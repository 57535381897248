import { ChecklistStyles } from "../../utils/styles"

let vs = ChecklistStyles()
const Selector = ({ setScreen = () => {}, screen, toChange }) => {
  let ChecklistStyles = vs()

  const change = (ventana) => {
    let validaciones = toChange()
    switch (ventana) {
      case "DatosOrdenServicio":
        setScreen(ventana)
        break
      case "Diagnostico":
        if (validaciones?.dg) {
          setScreen(ventana)
        }
      case "Observacion":
        if (validaciones?.dg) {
          setScreen(ventana)
        }
        break
      case "ManoObra":
        if (validaciones?.dg) {
          setScreen(ventana)
        }
        break
      case "Refaccion":
        if (validaciones?.dg) {
          setScreen(ventana)
        }
        break
      case "OtroTaller":
        if (validaciones?.dg) {
          setScreen(ventana)
        }
        break
    }
  }

  return (
    <div className={ChecklistStyles.selectionContainer}>
      <div className={ChecklistStyles.buttonArea}>
        <button
          onClick={(e) => change("DatosOrdenServicio")}
          className={
            screen === "DatosOrdenServicio"
              ? ChecklistStyles.buttonFocus
              : ChecklistStyles.button
          }
        >
          Datos Orden de Servicio
        </button>
      </div>
      <div className={ChecklistStyles.buttonArea}>
        <button
          onClick={(e) => change("Observacion")}
          className={
            screen === "Observacion"
              ? ChecklistStyles.buttonFocus
              : ChecklistStyles.button
          }
        >
          Observaciones
        </button>
      </div>
      <div className={ChecklistStyles.buttonArea}>
        <button
          onClick={(e) => change("Diagnostico")}
          className={
            screen === "Diagnostico"
              ? ChecklistStyles.buttonFocus
              : ChecklistStyles.button
          }
        >
          Diagnosticos
        </button>
      </div>
      <div className={ChecklistStyles.buttonArea}>
        <button
          onClick={(e) => change("ManoObra")}
          className={
            screen === "ManoObra"
              ? ChecklistStyles.buttonFocus
              : ChecklistStyles.button
          }
        >
          Manos de Obra
        </button>
      </div>
      <div className={ChecklistStyles.buttonArea}>
        <button
          onClick={(e) => change("Refaccion")}
          className={
            screen === "Refaccion"
              ? ChecklistStyles.buttonFocus
              : ChecklistStyles.button
          }
        >
          Refacciones
        </button>
      </div>
      <div className={ChecklistStyles.buttonArea}>
        <button
          onClick={(e) => change("OtroTaller")}
          className={
            screen === "OtroTaller"
              ? ChecklistStyles.buttonFocus
              : ChecklistStyles.button
          }
        >
          TOT
        </button>
      </div>
    </div>
  )
}
export default Selector
